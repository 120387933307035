if $(document).find("#DND--portfolio-modal").is("div")
  DNDPortfolioModalVue = new Vue(
    el: "#DND--portfolio-modal"
    data: {
      itemData: null
      showState: false,
      showStateMobileGallery: false,
      slider: null,
      currentSlide: 0,
      currentPage: "/profile/",
      navigationPage: {
        total: 0,
        current: 0,
        next: "",
        prev: ""
      },
      isLoaded: false
    },

    computed: {
      currentSlideFormated: ()->
        ind = this.currentSlide + 1
        if ind > 9
          return ind
        else
          return "0" + ind

      canShowModal: ()->
        return !!this.itemData && !!this.showState

      getFirstImg: ()->
        returnData = null

        if this.itemData
          returnData = this.itemData.photo.find (el) => return true
          if !returnData && this.itemData.photoPreview
            returnData = this.itemData.photoPreview

        return returnData
      getMobileBG: ()->
        returnStyle = {}
        if this.getFirstImg
          returnStyle['background'] = 'url("' + this.getFirstImg + '") no-repeat center/cover'

        return returnStyle
      getDescktopBG: ()->
        returnStyle = {}
        if this.getFirstImg
          returnStyle['background'] = 'url("' + this.getFirstImg + '") no-repeat center/contain'

        return returnStyle
    },
    methods: {
      switchMobileGalleryState: ()->
        this.showStateMobileGallery = !this.showStateMobileGallery
      getStyleForBG: (src)->
        returnStyle = {}
        if this.getFirstImg
          returnStyle['background'] = '#0F1118 url("' + src + '") no-repeat center/cover'

        return returnStyle

      changePopupState: ()->
        this.showState = !this.showState

      changeItemData: (itemData)->
        console.log itemData
        this.itemData = itemData

      openFB: (ind)->
        if($(document).find('.modal-gallery--ss').hasClass('swipping'))
          return false
        if this.itemData && this.itemData.photo.length > 0
          pictureList = this.itemData.photo.map((el) =>
            el
          )
          itemBeforeIndex = pictureList.splice(0, ind)
          pictureList = pictureList.concat(itemBeforeIndex)
          console.log(pictureList, ind)
          Fancybox.show(pictureList.map((el) =>
              return {src: el}
            )
          );


      openModal: (itemData) ->
        $(document).find('.modal-gallery--ss').slick('unslick');
        this.showState = true
        this.changeItemData(JSON.parse(itemData))
        this.updateNavigation()
        if this.itemData
          window.history.pushState('page2', 'Title', this.itemData.url)
        setTimeout ()=>
          if this.itemData && this.itemData.photo.length > 0
            this.slider = $(document).find('.modal-gallery--ss').slick({
              rows: 0,
              verticalSwiping: true,
              vertical: true,
              arrows: false,
              slidesToScroll: 1,
              slidesToShow: 1,
              centerMode: true,
              centerPadding: "0px",
              swipeToSlide: true,
              focusOnSelect: true,
            });

            this.slider.on 'wheel', (e) ->
              e.preventDefault()
              if e.originalEvent.deltaY > 0
                $(this).slick 'slickNext'
              else
                $(this).slick 'slickPrev'
              return
            $(document).find('.modal-gallery--ss').on "swipe", (slick, direction)=>
              $(document).find('.modal-gallery--ss').addClass('swipping');
            $(document).find('.modal-gallery--ss').on "afterChange", (slick, currentSlide)=>
              setTimeout( () =>
                $(document).find('.modal-gallery--ss').removeClass('swipping')
              , 110 );

# nextLideInd = this.currentSlide+1
              nextLideInd = this.slider[0].slick.slickCurrentSlide()
              if this.itemData.photo.length == nextLideInd
                this.currentSlide = 0
              else
                this.currentSlide = nextLideInd
# this.currentSlide = this.slider[0].slick.slickCurrentSlide()
        , 600

      closeModal: ()->
        window.history.back()
        this.showState = false
        if this.slider
          this.slider[0].slick.unslick()

        $(document).find('.single-slider__button-prev').unbind()
        $(document).find('.single-slider__button-next').unbind()
        this.currentSlide = 0
        this.changeItemData(null)

        this.navigationPage.total = 0
        this.navigationPage.current = 0
        this.navigationPage.next = ""
        this.navigationPage.prev = ""

      updateNavigation: ()->
        $.ajax({
          type: "GET",
          url: "/ajax/getPortfolioNavigation.php",
          data: {
            secID: $(document).find(".yoko-current-category-id").attr("data-id"),
            id: this.itemData.id
          },
          success: (data) =>
            this.navigationPage.total = data.total
            this.navigationPage.current = data.current
            this.navigationPage.next = data.next
            this.navigationPage.prev = data.prev
        })

      navigateGoNext: ()->
        this.isLoaded = true
        if (this.navigationPage.total == this.navigationPage.current)
          this.navigationPage.current = 1
        else
          this.navigationPage.current++

        $.ajax({
          type: "GET",
          url: this.navigationPage.next,
          data: {
            PORTFOLIO_GET_AJAX: "Y"
          },
          success: (data) =>
            this.openModal(data)
            this.isLoaded = false
        })

      navigateGoPrev: ()->
        this.isLoaded = true
        if (1 == this.navigationPage.current)
          this.navigationPage.current = this.navigationPage.total
        else
          this.navigationPage.current--

        $.ajax({
          type: "GET",
          url: this.navigationPage.prev,
          data: {
            PORTFOLIO_GET_AJAX: "Y"
          },
          success: (data) =>
            this.openModal(data)
            this.isLoaded = false
        })

    },
    mounted: ()->
      console.log "test"
      this.currentPage = String(location.pathname)
  )
